import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import sendRequest from "@ecom/ui/utils/sendRequest"
import clsx from "clsx"

import VerifyCode from "@ecom/ui/components/VerifyCode"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import HelperText from "./HelperText"

import { clearIDBData, getManyIDBValues } from "../../utils/idbUtils"

import * as styles from "./verifySMS.module.scss"

const TIMER_START = 45

export default function NewVerifySMS() {
  const [apiId, setApiId] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [errorMsg, setErrorMsg] = useState("Неверный код")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState<number | null>(null)
  const [isSending, setIsSending] = useState(false)
  const [isShowPhoneField, setIsShowPhoneField] = useState(false)
  const [isShowHint, setIsShowHint] = useState(false)

  useEffect(() => {
    getManyIDBValues(["phone", "apiId"]).then(([phone, requestId]) => {
      if (requestId) {
        setApiId(requestId)
      }
      if (phone) {
        setUserPhone(phone)
      }
    })
  }, [])

  useEffect(() => {
    let timerID: ReturnType<typeof setInterval>
    if (time > 0) {
      timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => clearTimeout(timerID)
  })

  function getNewCode(phone: string) {
    setCode(null)
    setIsError(false)
    pushToDataLayer({
      result: "resend",
    })
    sendRequest(`${process.env.GATSBY_APP_API_URL}/v2/app/public/phone/validate/`, { phone }, apiId)
      .then(() => {
        setTime(TIMER_START)
      })
      .catch(() => {
        setTime(0)
        setIsError(true)
        setErrorMsg("Превышен лимит запросов")
      })
  }

  function submitCode(smsCode: number) {
    sendRequest(
      `${process.env.GATSBY_APP_API_URL}/v2/app/public/phone/validate`,
      { code: smsCode },
      apiId
    )
      .then(({ result }: any) => {
        setIsSending(true)

        setIsShowPhoneField(false)
        pushToDataLayer({
          result,
        })
        if (result !== "validated") {
          setIsError(true)
          setIsSending(false)
        } else {
          navigate("/success/")
          clearIDBData()
        }

        switch (result) {
          case "exceeded":
            setErrorMsg("Время кода истекло")
            break
          case "timeout":
            setErrorMsg("Превышен лимит запросов")
            break

          default:
            break
        }
      })
      .catch((error: Error) => {
        setIsError(true)
        console.log(error.message)

        // navigate("/error/")
      })
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = e
    console.log(value)
    if (value === "") {
      setCode(null)
    } else {
      setCode(+value)
    }

    if (isError) {
      setIsError(false)
    }

    if (value.length === 4) {
      submitCode(+value)
    }
  }

  function showPhoneField() {
    setIsShowPhoneField(true)
  }

  function closeAlert() {
    setIsShowHint(false)
  }

  function handleSubmit({ phone }: { phone: string }) {
    sendRequest(
      `${process.env.GATSBY_APP_API_URL}/v2/app/public/phone/validate/`,
      { phone },
      apiId
    ).then(() => {
      try {
        setUserPhone(phone)
        setErrorMsg("")
        setIsError(false)
        setIsShowHint(true)
        pushToDataLayer({
          result: "resend",
        })
      } catch (error) {
        setIsError(true)
        setErrorMsg("При вводе номера произошла ошибка")
      }
    })
  }

  return (
    <div className="text-center">
      <h2 className={styles.head}>Введите код из СМС</h2>
      {userPhone ? (
        <p className={clsx(styles.description, styles.hintSended)}>
          Мы отправили код подтверждения
          <br />
          на номер <span>{userPhone}</span>
        </p>
      ) : null}
      <div>
        <VerifyCode
          autoFocus
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChange}
          value={code}
          error={isError}
          errorMsg={errorMsg}
          disabled={isSending}
          helperText={
            <HelperText
              isRunning={time !== 0}
              timer={time}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => getNewCode(userPhone)}
            />
          }
          classes={{
            verifyCodeInput: styles.input,
            errorTextRoot: styles.errText,
            formControlRoot: styles.verifyCode,
          }}
        />
      </div>
      {isShowPhoneField ? (
        // eslint-disable-next-line react/jsx-no-bind
        <div style={{ textAlign: "center" }}>
          <FormLanding name="verifySMSForm" onSubmit={handleSubmit}>
            <PhoneField name="phone" label="Телефон" style={{ width: 290 }} />
            <SubmitButton style={{ width: 290 }}>Отправить</SubmitButton>
          </FormLanding>
        </div>
      ) : (
        <p className={styles.description}>
          Ошиблись при вводе номера?
          <br />
          <button className={styles.btnCorrect} onClick={showPhoneField} type="button">
            Исправьте
          </button>
          его, и мы вышлем
          <br />
          подтверждение снова
        </p>
      )}
      <Snackbar
        open={isShowHint}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={closeAlert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert elevation={6} variant="filled" severity="info">
          Телефон обновлен
        </Alert>
      </Snackbar>
    </div>
  )
}
