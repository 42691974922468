import React from "react"

import clsx from "clsx"

import * as styles from "./helperText.module.scss"

type HelperTextProps = {
  isRunning: boolean
  timer: number
  onClick: () => void
}

export default function HelperText({ isRunning, timer, onClick }: HelperTextProps) {
  if (isRunning) {
    return (
      <span className={styles.helperText}>
        Отправить код повторно через <b>{timer} сек</b>
      </span>
    )
  }

  return (
    <button type="button" className={clsx(styles.helperText, styles.retry)} onClick={onClick}>
      Отправить код повторно
    </button>
  )
}
