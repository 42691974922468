import React from "react"

import Container from "@ecom/ui/components/Container"
import Layout from "../components/Layouts/secondaryPage"
import VerifySMS from "../components/VeriFyPhone"

export default function VerifyPhonePage() {
  return (
    <Layout title="Подтверждение номера">
      <section className="form-outer">
        <Container>
          <VerifySMS />
        </Container>
      </section>
    </Layout>
  )
}
